<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        Реестр ПО и продукции электронной промышленности
      </div>
      <div class="product-block-desc">
        Проект - платформа для регулирования и контроля производства, использования программного обеспечения и другой электронной продукции в Казахстане. В системе есть возможность подачи заявок на включение в реестр со стороны компаний. 
      </div>
<!--      <div class="product-img-djoon-1" :style="{ 'background-image': `url('/css/images/DJOON-1.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/reestr-back1.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/reestr-back2.png"
          fit="fit"></el-image>

      <div class="product-block-desc">
        Заявка может быть одобрена только при соблюдении определенных условий, согласно законодательству РК. Далее, заявка попадает на рассмотрение комиссии экспертов. После рассмотрения заявка может быть одобрена или отклонена. Если заявка одобрена, она попадает в реестр программного обеспечения и электронной промышленности Казахстана. Также в системе есть интеграции с платформой egov.kz и тендерными площадкам.
      </div>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/reestr-back3.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/reestr-back4.png"
          fit="fit"></el-image>


    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
